import React, { useEffect, useRef }
from 'react';import Typed from 'typed.js';

const Hero = ({ isDarkMode, toggleDarkMode }) =>{
   
  const typedRef = useRef(null);

  useEffect(() => {
    const options = {
      strings: ["Front End Web Developer", "Back End Web Developer"],
      typeSpeed: 100,
      backSpeed: 60,
      loop: true,
      startDelay: 500, // Optional: Delay before typing starts
      backDelay: 1500, // Optional: Delay before starting to backspace
    };

    const typed = new Typed(typedRef.current, options);

    return () => {
      typed.destroy();
    };
  }, []);

  
    return(
     
        <>

<section id="hero" className={`d-flex align-items-center" ${isDarkMode ? 'dark' : ''}`}>
<div className="container d-flex flex-column align-items-center" data-aos="zoom-in" data-aos-delay="100">
  <h1 className="text-1" >Hello, I'M Abir Hal</h1>
  <div className="text-2" > <span ref={typedRef}></span>
  </div>
        <a  href="mailto:abirhalouadji@gmail.com" target="_thapa" className=" hire">Hire Me</a>
</div>
</section>

</>
    )
}
export default Hero