import React, { useEffect} from 'react';

import p7 from "../../assest/p7.PNG"
import p10 from "../../assest/p10.PNG"
import p0 from "../../assest/p0.PNG"
import p6 from "../../assest/p6.PNG"
import p2 from "../../assest/p2.PNG"
import p1 from "../../assest/p1.PNG"
import p3 from "../../assest/p3.PNG"
import Fly from "../../assest/fly1.PNG"


import RAM from "../../assest/ramzi.PNG"
const Portfolio = ({ isDarkMode, toggleDarkMode }) =>{
  
    return(
     
        <>
<section id="work" className="portfolio-mf sect-pt4 route">
<div className="container">
    <a name="portfolio"></a>

  <div className="row">
    <div className="col-sm-12">
      <div className="title-box text-center">
        <h2 className={`title ${isDarkMode ? 'title-dark' : ''}`}>
          Portfolio
        </h2>
        <div className="line">
          <div className="line1"></div>
      </div>
        <p className="subtitle-a">
          My Amazing Work
        </p>
      </div>
    </div>
  </div>
  <div className="row">
    
   
    <div className="col-md-4">
      <div className="work-box">
        <a href="https://t-eb-k.com/index.php"   target="_blank">
          <div className="work-img">
            <img src={p7} alt="" className="img-fluid" />
          </div>
        </a>
     
      </div></div>
<div className="col-md-4">
      <div className="work-box">
        <a href="https://bethought.org/" target="_blank">
          <div className="work-img">
            <img src={p10} alt="" className="img-fluid" />
          </div>
        </a>
      
      </div>
    </div>



       <div className="col-md-4">
      <div className="work-box">
        <a href="https://youtu.be/ne0SNXt9VM8" target="_blank">
          <div className="work-img">
            <img src={p0} alt="" className="img-fluid" />
          </div>
        </a>
       
      </div>
    </div>
          <div className="col-md-4">
      <div className="work-box">
        <a href="https://www.youtube.com/watch?v=sxfkxFHyqQQ" target="_blank">
          <div className="work-img">
            <img src={p6} alt="" className="img-fluid"/>
          </div>
        </a>
       
      </div>
    </div>
     <div className="col-md-4">
      <div className="work-box">
        <a href="https://ramzihalouadji.site/" target="_blank">
          <div className="work-img">
            <img src={RAM} alt="" className="img-fluid"/> 
          </div>
        </a>
      
      </div>
    </div>
    <div className="col-md-4">
      <div className="work-box">
        <a href="https://www.youtube.com/watch?v=Vz9MB-tLL5s&t=4s" target="_blank">
          <div className="work-img">
            <img src={Fly} alt="" className="img-fluid"/> 
          </div>
        </a>
      
      </div>
    </div>
      <div className="col-md-4">
      <div className="work-box">
        <a href="https://abirhal.github.io/movies/index.html" target="_blank">
          <div className="work-img">
            <img src={p2} alt="" className="img-fluid"/>
          </div>
        </a>
        
      </div>
    </div>
     <div className="col-md-4">
      <div className="work-box">
        <a href="https://abirhal.github.io/eliteCorp/index.html" target="_blank">
          <div className="work-img">
            <img src={p1} alt="" className="img-fluid"/>   


           
          </div>
          
        </a>
        
      </div>
    </div>
  
    <div className="col-md-4">
      <div className="work-box">
        <a href="https://abirhal.github.io/" target="_blank">
          <div className="work-img">
            <img src={p3} alt="" className="img-fluid"/>
          </div>
        </a>
      
      </div>
    </div>
  
</div>
</div>
</section>

</>
    )
}
export default Portfolio 