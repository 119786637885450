import React from 'react'
import msg from "../../assest/messanger.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


const Contact = ({ isDarkMode, toggleDarkMode }) =>{
    return(
     
        <>
    <section id="contact" className="paralax-mf footer-paralax bg-image sect-mt4 route " >
        <div className="overlay-mf"></div>
        <div className="container">
        <a name="contact"></a>

          <div className="row">
            <div className="col-sm-12">
              <div className="contact-mf">
                <div id="box-shadow-full" className="box-shadow-full">
                  <div className="row">
                    <div className="col">
                     
                        <h2 className={`title  text-center${isDarkMode ? 'title-dark' : ''}`}>
                          Contact me
                        </h2>
                        <div className="line">
                          <div className="line1"></div>
                      </div>
                      
                      <div>
                        <div className="contact__container" id="contact__container">
                          <div className="contact__content">
                          <div className="contact__info">
                              <div className="contact__card" id="contact-card">
                               <img src={msg}  className="contact__card-icon" id="contact__card-icon" alt="" />
                                  <h3 className="contact__card-title" id="contact__card-title">Massenger</h3>
                                  <a href="http://m.me/abir.hal.31?text=Hello%20i'm%20abir%20hal 
              " className="contact__button" id="contact__button"> <i className=' contact__button-icon' id="contact__button-icon"><FontAwesomeIcon icon={faArrowLeft} /></i>
                                   Send Message</a>
                              </div>
                          </div>
              
                          </div>
                        </div>
                      </div>
                    </div>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
      </>
    )
}
export default Contact