import React, { useEffect,useState}
from 'react';
import axios from "axios"
import {Link, useNavigate} from "react-router-dom";



const Addtest = () =>{
  
  const [Error, setError]= useState(null)
  const [inputs,setInputs]=useState({
    fullName:"",
    fullMsg:"",
    position:"",
   imageUrl:"",
   gender: "",
 
  })
/*
const handleFileChange = (e) => {
  const selectedFile = e.target.files[0];
  setFile(selectedFile);

  console.log('File selected:', selectedFile);
}; */
  const navigation = useNavigate()
/*
 
  const handleChange = (e) => {
    setInputs(prev =>({...prev,[e.target.name]:e.target.value}))

  };*/
  
  const handleChange = (e) => {
    setInputs({...inputs, [e.target.name]: e.target.value});
}

const handlePhoto =async (e) => {
  const file =e.target.files[0];
  if (file) {
    const compressedFile = await compressImage(file);

    const base64 = await convertToBase64(compressedFile); // Convert file to base64
    setInputs({
        ...inputs,
        imageUrl: base64, // Store base64 string in imageUrl
    });
   console.log("File selected and converted to base64:", base64);
} else {
    console.log("No file selected");
}
}

  const handelSubmit= async (e)=>{ 
    e.preventDefault();
  console.log('File selected:', inputs.imageUrl);
  console.log('Inputs:', inputs);

    const formData = new FormData();
    formData.append('fullName', inputs.fullName);
    formData.append('fullMsg', inputs.fullMsg);
    formData.append('position', inputs.position);
    if (inputs.imageUrl) {
      formData.append('imageUrl', inputs.imageUrl);
    } else {
      console.warn('No file selected');
    }

    for (let [key, value] of formData.entries()) {
   console.log(`${key}:`, value);
    }
  
    try {
      const response = await axios.post('api/msgs/add',  inputs);

    console.log('Message created:', response.data);
     console.log('Success:', response.data);

      alert("Thank you for your feedback!")

      navigation('/');
    } catch (err) {
      
    console.error('Error response:', err.response);
    setError(err.response?.data || 'An unexpected error occurred');
    }
  }
  
    return(
     
        <>

<section id="contact" className="paralax-mf footer-paralax bg-image sect-mt4 route " >
        <div className="overlay-mf"></div>
        <div className="container">
      

          <div className="row">
            <div className="col-sm-12">
              <div className="contact-mf">
                <div id="contact" className="box-shadow-full">
                  <div className="row">
                    <div className="col">
                     
                        <h2 className="title text-center">
                          Client Testimonial
                        </h2>
                        <div className="line">
                          <div className="line1"></div>
                      </div>
                      
                      <div>
                        <form  encType="multipart/form-data" method="post" onSubmit={handelSubmit} role="form"  className="php-email-form">
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input type="text" name="fullName" className="form-control" id="name" placeholder="Your Full Name *" value={inputs.fullName} required onChange={handleChange} />
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input type="text" name="position" className="form-control" id="position" placeholder="country *"value={inputs.position} onChange={handleChange}  />
                              </div>
                            </div>
            
                            <div className="col-md-12">
                              <div className="form-group">
                                <textarea className="form-control" name="fullMsg" rows="5" placeholder="Your Feedback *" required type="text" value={inputs.fullMsg} onChange={handleChange} ></textarea>
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input  type="hidden" className="form-control" name="imageUrl" id="imageUrl"                    onChange={handlePhoto}  accept=".jpg, .jpeg, .png"/>
                              </div> 
                            </div>
                            <div className="col-md-12 mb-3">
  <div className="form-group">
    <label>Gender *</label>
    <select name="gender" className="form-control" value={inputs.gender} onChange={handleChange} required>
      <option value="">Select Gender</option>
      <option value="male">Male</option>
      <option value="female">Female</option>
    </select>
  </div>
</div>

                            <div className="col-md-12 text-center">
                              <button type="submit"  name="submit" className="button button-a button-big button-rouded">Send</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>

  </>
    )
}
export default Addtest

const compressImage = (file, maxWidth = 1000, maxHeight = 1000) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let width = img.width;
      let height = img.height;

      // Maintain aspect ratio
      if (width > maxWidth || height > maxHeight) {
        if (width > height) {
          height = Math.floor((maxHeight / width) * height);
          width = maxWidth;
        } else {
          width = Math.floor((maxWidth / height) * width);
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', 0.7); // compress to JPEG with 70% quality
    };
    img.onerror = (err) => reject(err);
  });
};
function convertToBase64(file){
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result)
    };
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}