import React, { useEffect, useRef }
from 'react';
import htmlicon from "../../assest/html.png"
import js from "../../assest/js.png"
import node from "../../assest/node js.png"
import cssicon from "../../assest/css.png"

import reacticon from "../../assest/react js.png"
import sass from "../../assest/sass.png"
import php from "../../assest/php.png"
import mysql from "../../assest/mysql.png"
import boots from "../../assest/bootstrap.png"
import ex from "../../assest/ex.png"
import mo from "../../assest/mongo.png"

import git from "../../assest/git.png"
import github from "../../assest/github.png"






const Skills = ({ isDarkMode, toggleDarkMode }) =>{
  
    return(
     
        <>
<section id="skill" className=" pt-5 route  skill1">
    <div className="container">
    <a name="skills"></a>

      <div className="row">
        <div className="col-sm-12 skill">
          <div className="title-box ">
            <h2 className={`title ${isDarkMode ? 'title-dark' : ''}`}>my skills</h2>
            <div className="line">
              <div className="line1"></div>
          </div>
        </div>
          <div className="row wow bounceInUp" data-wow-offset="150" data-wow-duration="1s" >
            
            <div className="col-md-2 col-sm-3  shield">
              <img src={htmlicon} alt=""/>
              <a>
                HTML</a>
            </div>
            <div className="col-md-2 col-sm-3  shield">
              <img src={js} alt=""/><a>
               JS</a>
            </div>
            <div className="col-md-2 col-sm-3  shield">
              <img src={node} alt=""/>
              <a> Node JS / Express JS</a>
            </div>
            <div className="col-md-2 col-sm-3  shield">
              <img src={reacticon} alt=""/>
              <a>React JS</a>
            </div>
            <div className="col-md-2 col-sm-3  shield">
              <img className="" src={cssicon} alt=""/>
              <a  >
                Css</a>
            </div>
            <div className="col-md-2 col-sm-3  shield">
              <img className="" src={sass} alt=""/>
              <a  >
                Sass</a>
            </div>
            <div className="col-md-2  col-sm-3  shield">
              <img src={boots} alt=""/>
              <a> Bootstrap</a>
            </div>
            <div className="col-md-2  col-sm-3  shield">
              <img src={php} alt=""/>
              <a> PHP</a>
            </div>
            <div className="col-md-2  col-sm-3  shield">
              <img src={mysql} alt=""/>
              <a>Mysql</a>
            </div>
            <div className="col-md-2  col-sm-3  shield">
              <img src={mo} alt=""/>
              <a>Mongo DB</a>
            </div>
           
            <div className="col-md-2  col-sm-3  shield">
              <img src={git} alt=""/>
              <a>Git & Github</a>
            </div>
        </div>

        
        </div>
      </div>
    </div></section>

  </>
    )
}
export default Skills