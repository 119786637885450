import React, { useState, useEffect}  from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faArrowUp  } from "@fortawesome/free-solid-svg-icons";


const ScrollUp = () =>{
    const [isActive, setActive] = useState(false);

  const checkScrollTop = () => {
    if (window.scrollY > 100) {
        setActive(true);
    } else {
        setActive(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

    return(
     

<a href="#"  onClick={scrollToTop}
     className= {`back-to-top d-flex align-items-center justify-content-center ${isActive ? 'active' : ''}`}><i className="bi bi-arrow-up-short"> <FontAwesomeIcon    icon={faArrowUp } /></i></a>

    )
}

export default ScrollUp;