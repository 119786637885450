import logo from './logo.svg';
import './App.css';

import {
  createBrowserRouter,RouterProvider,Outlet,Navigate
} from "react-router-dom";

import Home from './Component/Home/Home';
import Addtest from './Component/Dash/testmonial/Addtest';
import Login from './Component/Dash/login/Login';
import Showtest from './Component/Dash/testmonial/Showtest';
import Register from './Component/Dash/register/Register';

function App() {

  const ProtectedRoute = ({ element }) => {
    const user = localStorage.getItem("token");
    //console.log("ProtectedRoute - User token:", user); // Debugging log
    return user ? element : <Navigate to="/" />; // Redirect to public route if not authenticated
  };
  
  const PublicRoute = ({ element }) => {
    const user = localStorage.getItem("token");
   // console.log("PublicRoute - User token:", user); // Debugging log
    return !user ? element : <Navigate to="/show" />; // Redirect to protected route if authenticated
  };
  const Layout = () => {
    return(
     <>
       
      <Outlet/>
     
     
     </>
    );
  }
   
const router =  createBrowserRouter([
  {
    path:"/",
    element : <Layout />,
    children:[
      {
        path:"/",
        element :  <Home/>, 
        
      }, {
        path:"/dash",
        element :  <PublicRoute  element={<Login />} />,
        
      },
      {
        path:"/client_testmonial",
        element : <Addtest />},
        
      
      
      {
        path:"/show",
        element :    <ProtectedRoute element={<Showtest />} />,
        
      } ,
      {
        path:"/register",
        element :   <ProtectedRoute element={<Register />} />,
      }
       
      
      
       
     
      
    ]
    
  } 
])
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
