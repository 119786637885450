import React, { useState,  useEffect  }  from 'react'
import logo from '../../assest/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {faMoon } from "@fortawesome/free-solid-svg-icons";
import {faList} from "@fortawesome/free-solid-svg-icons";
import {faX} from "@fortawesome/free-solid-svg-icons";





const Navbar = ({ isDarkMode, toggleDarkMode }) =>{
  const [isMobileNavActive, setMobileNavActive] = useState(false);
  const [isSticky, setSticky] = useState(false);

  const toggleMobileNav = () => {
    setMobileNavActive(!isMobileNavActive);
  };
  const handleScroll = () => {
    if (window.scrollY > 50) { // You can adjust this value to when you want the sticky to start
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
 

    return(
     
        <>
        <header id="header"  className={`fixed-top ${isSticky ? 'sticky' : ''} ${isDarkMode ? 'sticky-dark' : ''}`}>
        <div className="container-fluid d-flex justify-content-between align-items-center">
    
        
          <a href="" className={`logo ${isDarkMode ? 'logo-dark' : ''}`}><img src={logo} alt="" className={`img-fluid`}/></a>
    
          <nav id="navbar"  className={`navbar order-last order-lg-0 ${
            isMobileNavActive ? 'navbar-mobile' : ''
          } ${isDarkMode ? 'navlist-dark' : ''}`}>
            <ul>
              <li><a className="active" href="#home">Home</a></li>
              <li><a href="#about" className="navbar-brand">About</a></li>
              <li><a href="#skills" className="navbar-brand">Skills</a></li>
              <li><a href="#service" className="navbar-brand">Services</a></li>
              <li><a href="#portfolio" className="navbar-brand">Portfolio</a></li>
              <li><a href="#contact" className="navbar-brand">Contact Me</a></li>
            </ul>
            <i className={`mobile-nav-toggle ${isDarkMode ? 'fa-solid-dark' : ''}`} id="bi-list" onClick={toggleMobileNav}> <FontAwesomeIcon icon={ isMobileNavActive ? faX : faList} /></i>
          </nav>
    
          <div className={`header-social-links `}  id="header-social-links">
            <a href="#" className={`twitter ${isDarkMode ? 'fa-solid-dark' : ''}`}><i className={`bi bi-moon`}    ><FontAwesomeIcon icon={faMoon} onClick={toggleDarkMode}/></i></a>
          </div>
    
        </div>
    
      </header>
      </>
    )
}
export default Navbar