import React, { useEffect}
from 'react';
import ScrollReveal from 'scrollreveal';

const About = ({ isDarkMode, toggleDarkMode }) =>{
    useEffect(() => {
        const sr = ScrollReveal({
          distance: '45px',
          duration:1500,
          reset: true
        });
        
        sr.reveal('.about', {
          delay: 200,
          origin: 'bottom'
        });
      }, []);
    return(
     
        <>
 <a name="about"></a>

<div className="overview text-center about ">
    <div className="container">
        <h2 className={`title ${isDarkMode ? 'title-dark' : ''}`}>About</h2>
        <div className="line">
          <div className="line1"></div>
      </div> 
      <h2 className="h1">I'm Abir Hal and I'm a <span>Web Developer</span></h2>

      <p>
As a self-taught developer with a passion for technology and innovation with a acadimic background on studying computer science I bring a unique blend of skills to the table... Let's work together to explore how my diverse background can contribute to your project's success.</p>
        <a href="cv abir_hal_2.pdf" className="cv">Donwnload CV</a>
    
    </div>
  </div>

  </>
    )
}
export default About