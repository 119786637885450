import React from 'react'
import "./home.css";
import Navbar from '../navbar/Navbar';
import Hero from '../hero/Hero';
import About from '../About/About';
import Skills from '../skills/Skills';
import Service from '../services/service';
import Portfolio from '../portfolio/Portfolio';
import Contact from '../contact/Contact';
import Footer from '../footer/Footer';
import Social from '../socialMedia/Social';
import ScrollUp from '../scrollup/ScrollUp';
import Testmonial from '../testmonial/Testmonial';
import { useState } from 'react';
import "../../App.css"

const Home = () =>{
  const [isDarkMode, setDarkMode] = useState(false);
  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode);
  };
    return(
      <>
      <Navbar isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>
      <Hero isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>
      <main id="main" className={` ${isDarkMode ? 'dark' : ''}`}>
      < About isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>
      < Skills isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
      < Service isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>
      < Portfolio isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
      <Testmonial isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>
      <Contact isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />

      </main>
      <Footer isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>
      <Social isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode}/>
      < ScrollUp/>
     
      </>
    )
}
export default Home