import React from 'react'
import msg from "../../assest/messanger.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram} from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";






const Footer = ({ isDarkMode, toggleDarkMode }) =>{
    return(
     
        <>
  <a name="asfal" className={` ${isDarkMode ? 'dark' : ''}`}></a>
<div className={`footer ${isDarkMode ? 'footer-dark' : ''}`} id="footer">
  <div className="social-icons ">
     

    <a href="https://github.com/AbirHal"><i > <FontAwesomeIcon icon={faGithub} /></i></a>
    <a href="https://www.linkedin.com/in/abir-halouadji-3746b6203/"><i ><FontAwesomeIcon icon={faLinkedin} /></i></a>
    <a href="mailto:abirhalouadji@gmail.com"><i > <FontAwesomeIcon icon={faGoogle} /></i></a>
    <a href="https://www.instagram.com/abir_hal/"><i ><FontAwesomeIcon icon={faInstagram} /></i></a>

    <a href="https://www.facebook.com/abir.hal.31"><i className="fab fa-facebook-f"><FontAwesomeIcon icon={faFacebook} /></i></a>
    <a href="https://www.youtube.com/channel/UC9qeIYa_VGFr15_C9kASqKQ"><i> <FontAwesomeIcon icon={faYoutube} /></i></a>



  </div>
  <p className="copyright">All Rights Reserved © 2020 Abir Hal</p>

</div>



  </>
    )
}
export default Footer
