import React, { useEffect, useState}
from 'react';
import axios  from 'axios';
import {Link, useNavigate} from "react-router-dom";

function Login () {
  const navigate = useNavigate()
   const [data,setData]= useState({
    fullName:"",
    password:""

   })
 const [error, setError]= useState("");
 const handelChange= (event)=>{
  const { name, value } = event.target;
  setData({ ...data, [name]: value });
 


}
   const handelSubmit = async (e)=>{
    e.preventDefault();
    try {
      const { data:res }= await axios.post("api/admin/login",data);
     
      localStorage.setItem("token", res.token);
     
     navigate('/show');
     

      
    } catch (error) {
      if(error.response && error.response.status >=400 && error.response.status <500){
        setError(error.response.data.message)
      }
    }

   }


    return(
     
        <>
<section id="contact" className="paralax-mf footer-paralax bg-image sect-mt4 route " >
        <div className="overlay-mf"></div>
        <div className="container">
      

          <div className="row">
            <div className="col-sm-12">
              <div className="contact-mf">
                <div id="contact" className="box-shadow-full">
                  <div className="row">
                    <div className="col">
                     
                        <h2 className="title text-center">
                         welcome back to  abir hal dashboard 
                        </h2>
                        <div className="line">
                          <div className="line1"></div>
                      </div>
                      
                      <div>
                        <form onSubmit={handelSubmit} role="form"  className="php-email-form">
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input type="text" name="fullName" className="form-control" autoComplete="fullName" placeholder="Your Full Name *" required value={data.fullName}  onChange={handelChange}/>
                              </div>
                            </div>
                            
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <input type="password" name="password" className="form-control" autoComplete="current-password" placeholder="password *" required value={data.password} onChange={handelChange}/>
                              </div>
                            </div>
                            
            
                          
                           {error && <div> {error}</div>} 
                            <div className="col-md-12 text-center">
                              <button type="submit" name="submit" className="button button-a button-big button-rouded">Login</button>
                            </div>
                            {/***                           <Link to={'/register'}>dont have an account?</Link>
*/}
                          </div>
                        </form>
                      </div>
                    </div>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>

  </>
    )
}
export default Login