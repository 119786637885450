import React, { useEffect}
from 'react';
import "./Test.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {faPlus} from '@fortawesome/free-solid-svg-icons'

import { faRightFromBracket} from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';
import axios from "axios";

const Showtest = ({msg}) =>{
  const [msgs,setMsgs]= useState(null)
  const handleLougout= ()=>{
    localStorage.removeItem("token");
    window.location.reload();
  }
  useEffect(() => {
   const fetchMsgs = async () => {
     try {
       const response = await axios.get('/api/msgs');
       setMsgs(response.data);
     } catch (error) {
       console.error('Error fetching testimonials:', error);
     }
   };
   fetchMsgs();
 }, []);
 if (!msgs || !Array.isArray(msgs)) return <div>No testimonials available.</div>;
const deleteClick  = async (id) => {
 
  try {
    await axios.delete(`/api/msgs/${id}`);
    setMsgs(prevMsgs => prevMsgs.filter(msg => msg._id !== id));
    alert("you sure about delete this message!")
  } catch (error) {
    console.error('Error deleting message:', error);
  }
    }


    return(
     
        <>
   <div className="newArticle">
            <div className="content">
         
            <div className="topDiv flex"> 
            <div className="titleText ">
                    <span className='title'> Welcome To Bethought Dashboard</span>
                    <span className='sppp' onClick={handleLougout}> <FontAwesomeIcon icon={faRightFromBracket} /></span>

                </div>
               <div className=" ">
              
                </div></div> 
               <div className='ShowArticleContainer'> 
               <ul role="list" className="row" >
            
           
              <>   {msgs.map((msg) => (
               <a to='/singlearticle' className='col-xl-6 col-md-6 col-sm-12'  key={msg._id}>
                <li className="ar-li">
                    
                    <h2 className="ar-heading ">{msg.fullName} - {msg.position}</h2>
                     <p className="ar-text ">
                     {msg.fullMsg}
                    </p>
                   <div className="spans view">
                   <div className="edit-delete-buttons"> 
                     <span onClick={() => deleteClick(msg._id)}><FontAwesomeIcon icon={faTrash} /></span>
                     <span> <FontAwesomeIcon icon={faPlus }/></span>
                   </div></div>
                 
                  
                </li>
            </a>
              ))}
          
         
            
           </>
            </ul>
          
               </div>
        
            </div>

            
        </div>
        

  </>
    )
}
export default Showtest