import React, { useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ScrollReveal from 'scrollreveal';
import { useState } from 'react';
import axios from "axios";


const Testmonial = ({ isDarkMode, toggleDarkMode }) =>{
   const [msgs,setMsgs]= useState(null)
  
   useEffect(() => {
    const fetchMsgs = async () => {
      try {
        const response = await axios.get('/api/msgs');
        setMsgs(response.data);
      } catch (error) {
        //console.error('Error fetching testimonials:', error);
      }
    };
    fetchMsgs();
  }, []);
 
    useEffect(() => {
        const sr = ScrollReveal({
          distance: '45px',
          duration:1500,
          reset: true
        });
        
        sr.reveal('.testmonial', {
          delay: 200,
          origin: 'bottom'
        });
      }, []);
   

      // Ensure msgs is an array before attempting to map
      if (!msgs || !Array.isArray(msgs)) return <div>No testimonials available.</div>;
      
    return(
     
        <>

<section className="gradient-custom testmonial">

  <div className="container ">
    <div className="row d-flex  justify-content-center">
    <div className="title-box ">
            <h2 className={`title ${isDarkMode ? 'title-dark' : ''}`}>Testmonials</h2>
            <div className="line">
              <div className="line1"></div>
          </div>
        </div>
        
        <div className="col-md-12">
                            <div className="text-center mb-4 pb-2">
                                <i className="fas fa-quote-left fa-3x text-white"></i>
                            </div>

                            <div className="">
                                <div className="px-4 py-5">
                                    <div id="carouselDarkVariant" className="carousel slide carousel-dark" data-bs-ride="carousel">
                                        <div className="carousel-indicators">
                                        {msgs.map((_, index) => (
                        <button
                          key={index}
                          type="button"
                          data-bs-target="#carouselDarkVariant"
                          data-bs-slide-to={index}
                          className={index === 0 ? 'active' : ''}
                          aria-current={index === 0 ? 'true' : 'false'}
                          aria-label={`Slide ${index + 1}`}
                        ></button>
                      ))}</div>

                                        <div className="carousel-inner pb-5">
                                          
                      {msgs.map((msg, index) => (
                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={msg._id}>
                          <div className="row d-flex justify-content-center">
                            <div className="col-lg-10 col-xl-8">
                              <div className="row">
                                <div className="col-lg-4 d-flex justify-content-center">
                                  <img
src={msg.imageUrl}                                     className="rounded-circle shadow-1 mb-4 mb-lg-0"
                                    alt="avatar"
                                    width="150"
                                    height="150"
                                  />
                                </div>
                                <div className="col-12 col-md-9 col-lg-8 text-center text-lg-start mx-auto mx-lg-0">
                                  <h4 className="mb-4">{msg.fullName} - {msg.position}</h4>
                                  <p className="mb-0 pb-3">{msg.fullMsg}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                                           

                                           
                                        </div>

                                        <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselDarkVariant"
                      data-bs-slide="prev"
                    >
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselDarkVariant"
                      data-bs-slide="next"
                    >
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center mt-4 pt-2">
                                <i className="fas fa-quote-right fa-3x text-white"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Testmonial;