import React, { useEffect, useRef }
from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptop} from "@fortawesome/free-solid-svg-icons";
import { faCropAlt} from "@fortawesome/free-solid-svg-icons";
import { faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";





const Service = ({ isDarkMode, toggleDarkMode }) =>{
  
    return(
     
        <>
 <a name="service"></a>


 <div className=" overview services text-center " id="services">
  <div className="container">
      <h2 className={`title ${isDarkMode ? 'title-dark' : ''}`}>My Services</h2>
      <div className="line">
        <div className="line1"></div>
    </div>
      <div className="row wow bounceInUp" data-wow-offset="150" data-wow-duration="1s" >
          <div className="col-md web">
            <a><i><FontAwesomeIcon icon={faLaptop} /></i> 
              Web Design</a>
          </div>
          <div className="col-md responsive">
            <a> <i><FontAwesomeIcon icon={ faCropAlt} /></i>
              Responsive</a>
          </div>
          <div className="col-md shield">
            <a><i >
            <FontAwesomeIcon icon={faShieldAlt} />
          
            </i>
              Clean Code</a>
          </div>
          <div className="col-md shield">
            <a><i >
            <FontAwesomeIcon icon={faGlobe}/>
            </i>
            Hosting  <br />
            and <br /> Domain <br /> Managment</a>
          </div>
         
      </div>
  </div>
</div>


  </>
    )
}
export default Service